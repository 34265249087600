export default [
  {
    /* [ 0 ] */
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    /* [ 1 ] */
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Status",
    model: null,
    options: [
      {
        id: 0,
        label: "ALL",
      },
      {
        id: 5,
        label: "Settled Successfully",
      },
      {
        id: 2,
        label: "Declined",
      },
      {
        id: 6,
        label: "General Error",
      },
      {
        id: 7,
        label: "Comm Error",
      },
      {
        id: 8,
        label: "Captured/Pending Settlement",
      },
      {
        id: 10,
        label: "Authorized/Pending Capture",
      },
    ],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Program",
    model: null,
    options: [
      {
        id: 0,
        name: "All",
      },
    ],
    reduce: "id",
    selectText: "name",
    cols: 12,
  },
  {
    /* [ 2 ] */
    type: "select",
    margin: true,
    showLabel: true,
    label: "Advisor",
    model: null,
    options: [
      {
        id: 0,
        label: "All",
      },
    ],
    reduce: "id",
    selectText: "label",
    cols: 12,
  },
]
