<template>
  <div>
    <b-card no-body class="mb-0 border-0 p-0">
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="$refs['refAutomaticTable'].refresh()"
        @reset-all-filters="refreshTable"
      >
        <b-table
          slot="table"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          select-mode="multi"
          responsive="sm"
          table-class="text-nowrap"
          small
          sort-icon-left
          ref="refAutomaticTable"
          :items="myProvider"
          :fields="filteredFields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
          sticky-header="68vh"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(settlement_date)="data">
            <div class="d-flex flex-column">
              <span> {{ data.item.settlement_date | myGlobalDay }} </span>
            </div>
          </template>
          <template #cell(status)="data">
            <div :class="[1, 5].includes(data.item.status_transaction_id) ? 'text-success' : (data.item.status_transaction_id === 8 ? 'text-warning' : 'text-danger')">
              <span>{{ data.item.status }}</span>
            </div>

          </template>
          <template #cell(created_at)="data">
            <div class="d-flex flex-column">
              <span> {{ data.item.user_name }} </span>
              <span> {{ data.item.created_at | myGlobalDay }} </span>
            </div>
          </template>
        </b-table>
      </filter-slot>
    </b-card>
  </div>
</template>

<script>
import RegisterPaymentService from "@/views/administration/views/register-payment/register-payment.service";
import ClientsService from "@/views/commons/components/clients/services/clients.services";
import GlobalService from "@/service/global";
export default {
  props:{
    tab: {
      type: Number,
      default: "automatic",
    },
    fields:{
      type: Array,
      default: () => []
    },
    filters:{
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.getPrograms();
    if(this.tab === 2 || this.tab === 3){
      this.getAdvisors();
    }
  },
  computed: {
    filteredFields() {
      return this.fields.filter((item) => item.visible);
    },
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: null,
      },
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      isBusy: false,
    };
  },
  methods: {
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async myProvider(ctx) {
      try {
        const params = {
          advisor: this.filters[4].model,
          from: this.filters[0].model,
          program: this.filters[3].model,
          status: this.filters[2].model,
          text: this.filterPrincipal.model,
          to: this.filters[1].model,
          page: ctx.currentPage,
          type: this.tab,
        };
        const { data } =
          await RegisterPaymentService.getAdministrationAutomaticPayments(
            params
          );
        this.startPage = data.from;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.totalRows = data.total;
        this.toPage = data.to;
        return data.data || [];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    refreshTable() {
      this.$refs.refAutomaticTable.refresh();
    },
    async getAdvisors() {
      try {
        const { data: advisors } = await GlobalService.getOwners({
          modul: 0,
          body: {
            roles: "[3]",
            type: 0,
          },
        });
        this.filters[4].options = [
          ...this.filters[4].options,
          ...advisors,
        ];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getPrograms() {
      try {
        const { data: programs } = await ClientsService.programs();
        this.filters[3].options = [...this.filters[3].options, ...programs];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>
