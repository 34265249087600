<template>
  <div>
    <!-- Tab 1 hace referencia al type_transaction = 1 (Automatic) -->
    <automatic-manual-table
      v-if="$route.name == 'adm-register-payments-automatic'"
      :tab="1"
      :fields="fields"
      :filters="filters"
    />
    <router-view v-if="['adm-history-imports', 'adm-history-automatic-import'].includes($route.name)"  />
  </div>
</template>

<script>
import fields from "@/views/administration/views/register-payment/data/fields";
import filters from "@/views/administration/views/register-payment/data/filters";
import AutomaticManualTable from "@/views/administration/views/register-payment/components/AutomaticManualTable.vue";
export default {
  components: {
    AutomaticManualTable,
  },
  data() {
    return {
      fields,
      filters,
    };
  },
};
</script>

<style>
</style>